var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "header" } }, [
    _c(
      "div",
      { staticClass: "header space-between" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.device !== "mobile",
                expression: "device!=='mobile'"
              }
            ],
            staticClass: "logo"
          },
          [
            _c("img", {
              attrs: { src: require("../assets/logo.png"), alt: "诺辉创投" }
            })
          ]
        ),
        _c("transition", { attrs: { name: "draw" } }, [
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.device !== "mobile" || _vm.show_list,
                  expression: "device!=='mobile' || show_list"
                }
              ],
              staticClass: "headerMenu clearfix j_PopupMenu"
            },
            [
              _vm._l(_vm.list, function(items, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    class: _vm.activeMenu == items.name ? "active" : ""
                  },
                  [
                    _vm.linguisticType == 0
                      ? _c(
                          "a",
                          {
                            staticClass: "menuItem",
                            on: {
                              click: function($event) {
                                return _vm.changeRoute(items, "child")
                              }
                            }
                          },
                          [_vm._v(_vm._s(items.name))]
                        )
                      : _vm._e(),
                    _vm.linguisticType == 1
                      ? _c(
                          "a",
                          {
                            staticClass: "menuItem",
                            on: {
                              click: function($event) {
                                return _vm.changeRoute(items, "child")
                              }
                            }
                          },
                          [_vm._v(_vm._s(items.value))]
                        )
                      : _vm._e(),
                    items.children
                      ? _c("div", { staticClass: "headerSecMenuBg" })
                      : _vm._e(),
                    items.children
                      ? _c(
                          "dl",
                          { staticClass: "headerSecMenu clearfix" },
                          _vm._l(items.children, function(item, i) {
                            return _c(
                              "dd",
                              {
                                key: i,
                                on: {
                                  click: function($event) {
                                    return _vm.changeRouteChild(items, item)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                )
              }),
              _c("li", { staticClass: "language" }, [
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.changeLanguage("1")
                      }
                    }
                  },
                  [_vm._v("EN")]
                ),
                _vm._v("|"),
                _c(
                  "span",
                  {
                    on: {
                      click: function($event) {
                        return _vm.changeLanguage("0")
                      }
                    }
                  },
                  [_vm._v("中")]
                )
              ])
            ],
            2
          )
        ]),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.device == "mobile",
                expression: "device == 'mobile'"
              }
            ],
            staticClass: "login-btn-top"
          },
          [
            _c("img", {
              attrs: { src: require("../assets/logo.png"), alt: "" },
              on: { click: _vm.changeLogin }
            })
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.device == "mobile",
                expression: "device == 'mobile'"
              }
            ],
            staticClass: "h_left"
          },
          [
            _c(
              "em",
              {
                staticClass: "headerMenuIcon icofont j_PopupMenuIcon",
                on: { click: _vm.changeList }
              },
              [_c("span")]
            )
          ]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }