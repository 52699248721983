var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer-content" }, [
      _c("div", { staticClass: "footer-top" }, [
        _c("div", { staticClass: "top-left" }, [
          _c("img", {
            staticClass: "footer-logo",
            attrs: { src: require("../assets/footerLogo.png") }
          }),
          _vm.linguisticType == 0
            ? _c(
                "ul",
                { staticClass: "nav-list" },
                _vm._l(_vm.list, function(v, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      on: {
                        click: function($event) {
                          return _vm.changeRoute(v)
                        }
                      }
                    },
                    [_vm._v(_vm._s(v.name))]
                  )
                }),
                0
              )
            : _vm._e(),
          _vm.linguisticType == 1
            ? _c(
                "ul",
                { staticClass: "nav-list" },
                _vm._l(_vm.list, function(v, i) {
                  return _c(
                    "li",
                    {
                      key: i,
                      on: {
                        click: function($event) {
                          return _vm.changeRoute(v)
                        }
                      }
                    },
                    [_vm._v(_vm._s(v.value))]
                  )
                }),
                0
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "top-right" }, [
          _c("div", [
            _c("h3", { staticClass: "email-title" }, [
              _vm._v(_vm._s(_vm.ruleform.headLine))
            ]),
            _c("p", { staticClass: "email-word" }, [
              _vm._v(_vm._s(_vm.ruleform.mailbox))
            ])
          ]),
          _c("img", {
            staticClass: "footer-weixin",
            attrs: { src: require("../assets/weixin.jpg") }
          })
        ])
      ]),
      _c("div", { staticClass: "footer-bottom" }, [
        _c(
          "div",
          { staticClass: "bottom-set" },
          [
            _c("p", { staticClass: "bottom-word" }, [
              _vm._v("©2021 by 诺辉创投（NHH Ventures）")
            ]),
            _vm._l(_vm.set_list, function(v, i) {
              return _c("div", { key: i, staticClass: "setting" }, [
                v.pictureAddress != ""
                  ? _c("img", { attrs: { src: v.pictureAddress } })
                  : _vm._e(),
                _c("p", [_vm._v(_vm._s(v.headLine))]),
                _c("p", [_vm._v(_vm._s(v.intro))])
              ])
            })
          ],
          2
        ),
        _c("p", { staticClass: "img-illustrate" }, [
          _vm._v(_vm._s(_vm.ruleform.pictureDescribe))
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }