//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: "",

  data() {
    return {
      url_list: [],
      gov: '京ICP备2021001117号-1',
      record_code: '11010602007760',
      linguisticType: localStorage.getItem('linguisticType'),
      set_list: [],
      ruleform: {},
      list: [{
        name: '首页',
        path: '/about',
        value: 'About'
      }, {
        name: '投资组合',
        path: '/portfolio',
        value: 'Portfolio'
      }, {
        name: '新闻中心',
        path: '/news',
        value: 'News'
      }, {
        name: '联系我们',
        path: '/contact',
        value: 'Contact'
      }]
    };
  },

  created() {
    // this.getList()
    this.getData();
    this.getPictureDescribe();
  },

  methods: {
    getList() {
      this.$http.get(this.$api.front_address_list).then(res => {
        if (res.code == 0) {
          this.url_list = res.data.rows;
        }
      });
    },

    getData() {
      this.$http.get(this.$api.listPublicSettings, {
        params: {
          linguisticType: this.linguisticType
        }
      }).then(res => {
        this.set_list = res.data.rows;
      });
    },

    getPictureDescribe() {
      this.$http.get(this.$api.listContactUs, {
        params: {
          linguisticType: localStorage.getItem('linguisticType')
        }
      }).then(res => {
        if (res.code == 0) {
          this.ruleform = res.data.rows[0];
        }
      });
    },

    changeRoute(item) {
      this.$router.push({
        path: item.path
      });
    }

  }
};