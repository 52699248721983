import store from '@/store';
const {
  body
} = document;
const WIDTH = 1024; // refer to Bootstrap's responsive design

export default {
  watch: {
    $route(route) {
      // console.log(this.sidebar)
      //&& this.sidebar.opened
      if (this.device === 'mobile') {
        store.dispatch('closeSideBar', {
          withoutAnimation: false
        });
      }
    }

  },

  beforeMount() {
    window.addEventListener('resize', this.$_resizeHandler);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.$_resizeHandler);
  },

  mounted() {
    const isMobile = this.$_isMobile();

    if (isMobile) {
      store.dispatch('toggleDevice', 'mobile'); // store.dispatch('app/closeSideBar', { withoutAnimation: true })

      store.dispatch('bodyWidth', document.body.clientWidth);
    }
  },

  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    $_isMobile() {
      const rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    },

    $_resizeHandler() {
      if (!document.hidden) {
        const isMobile = this.$_isMobile();
        store.dispatch('bodyWidth', document.body.clientWidth);
        store.dispatch('toggleDevice', isMobile ? 'mobile' : 'pc');

        if (isMobile) {// store.dispatch('closeSideBar', { withoutAnimation: true })
        }
      }
    }

  }
};