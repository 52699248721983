//
//
//
//
//
//
//
//
//
//
//
//
import { mapState } from 'vuex';
export default {
  name: "appMain",

  data() {
    return {
      code_img: '',
      phone: '',
      timer: null,
      show_float: true,
      scrollTop: "",
      goTopShow: false,
      float_data: {
        pictureAddress: ''
      }
    };
  },

  created() {},

  watch: {
    scrollTop() {
      if (this.scrollTop > 500) {
        this.goTopShow = true;
      } else {
        this.goTopShow = false;
      }
    }

  },
  computed: { ...mapState({
      device: state => state.device
    })
  },

  mounted() {},

  // 销毁页面滚动
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  methods: {
    // 页面滚动
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (this.scrollTop > 500) {
        this.goTopShow = true;
      }
    },

    // 回到顶部
    goTop() {
      let timer = null,
          _that = this;

      cancelAnimationFrame(timer);
      timer = requestAnimationFrame(function fn() {
        if (_that.scrollTop > 0) {
          _that.scrollTop -= 250;
          document.body.scrollTop = document.documentElement.scrollTop = _that.scrollTop;
          timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(timer);
          _that.goTopShow = false;
        }
      });
    }

  }
};