//
//
//
//
//
//
//
//
import mHeader from './header';
import mFooter from './footer';
import appMain from './appMain';
export default {
  name: "",
  components: {
    mHeader,
    mFooter,
    appMain
  }
};