//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import nxLangSelect from '@/components/nx-lang-select/index';
import ResizeMixin from './mixin/ResizeHandler';
import { mapState } from 'vuex';
import store from '@/store';
export default {
  name: "",

  data() {
    return {
      show_list: false,
      language: 'EN',
      select_value: '账号登录',
      list: [{
        name: '首页',
        path: '/about',
        value: 'About'
      }, // {
      //     name:'投资策略',
      //     path:'/investment',
      //     value:'Investment'
      // },
      // {
      //     name:'团队',
      //     path:'/team',
      //     value:'Team'
      // },
      {
        name: '投资组合',
        path: '/portfolio',
        value: 'Portfolio'
      }, {
        name: '新闻中心',
        path: '/news',
        value: 'News'
      }, {
        name: '联系我们',
        path: '/contact',
        value: 'Contact'
      }],
      value: '',
      linguisticType: localStorage.getItem('linguisticType'),
      offsetTop: ''
    };
  },

  mixins: [ResizeMixin],
  components: {
    nxLangSelect
  },
  computed: {
    activeMenu() {
      const route = this.$route;
      const {
        meta
      } = route;
      return meta.title;
    },

    ...mapState({
      device: state => state.device,
      hasLogin: state => state.hasLogin,
      showLogin: state => state.showLogin,
      info: state => state.info
    })
  },

  mounted() {
    store.dispatch('setLanguage', localStorage.getItem('linguisticType'));
  },

  methods: {
    async logout() {
      await this.$store.dispatch('logout');

      if (this.$route.path == '/userInfo') {
        this.$router.push(`/index`);
      }
    },

    changeRoute(item, type) {
      if (type == 'parent' && item.children || item.path == this.$route.path) {
        return false;
      }

      this.$router.push({
        path: item.path
      });
      this.show_list = !this.show_list;
    },

    changeRouteChild(parent, child) {
      if (child.path == this.$route.query.hash) {
        return false;
      } // if(typeof child.path != 'string' && child.path.name == '科普视频'){
      //
      // }


      this.$router.push({
        path: parent.path,
        query: {
          hash: typeof child.path == 'string' ? child.path : JSON.stringify(child.path)
        }
      });
    },

    changeList(e) {
      e.preventDefault();
      this.show_list = !this.show_list;
      return false;
    },

    changeLogin(bool) {
      this.$store.commit('showLogin', bool);
    },

    changeLanguage(linguisticType) {
      store.dispatch('setLanguage', linguisticType);
      location.reload();
    }

  }
};