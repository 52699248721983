var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-main" },
    [
      _c("div", { attrs: { id: "goTop" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.goTopShow,
                expression: "goTopShow"
              }
            ],
            staticClass: "goTop",
            on: { click: _vm.goTop }
          },
          [_c("i", { staticClass: "el-icon-caret-top goTopIcon" })]
        )
      ]),
      _c("router-view")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }