import Vue from 'vue';
import Vuex from 'vuex';
import { setLogin, getLogin, removeLogin } from '../common/cache.js';
import Cookies from 'js-cookie';
import { manageLogin } from '@/api/user';
import router, { resetRouter } from '@/router';
Vue.use(Vuex);
const user_status = getLogin("isLogin"); // 获取用户是否登录

const store = new Vuex.Store({
  state: {
    hasLogin: user_status ? true : false,
    // 登录状态
    loginProvider: "",
    params: {},
    // 中英文
    linguisticType: localStorage.getItem('linguisticType') || 0,
    device: 'pc',
    token: '',
    // info: info || {},
    sidebar: {
      opened: Cookies.get('sidebarStatus') ? !!+Cookies.get('sidebarStatus') : true,
      withoutAnimation: false
    },
    size: Cookies.get('size') || 'medium',
    tagsView: true,
    showLogin: false,
    bodyWidth: ''
  },
  mutations: {
    LOGIN(state, user) {
      state.hasLogin = true;
      setLogin('isLogin', true);
    },

    LOGOUT(state) {
      state.hasLogin = false;
    },

    showLogin(state, bool) {
      state.showLogin = bool == 'false' ? false : !state.showLogin;
    },

    // 中英文
    SET_LANGUAGE: (state, language) => {
      state.linguisticType = language;
      localStorage.setItem('linguisticType', language);
    },
    TOGGLE_DEVICE: (state, device) => {
      state.device = device;
    },
    BODY_WIDTH: (state, width) => {
      state.bodyWidth = width;
    },
    TOGGLE_SIDEBAR: state => {
      state.sidebar.opened = !state.sidebar.opened;
      state.sidebar.withoutAnimation = false;

      if (state.sidebar.opened) {
        Cookies.set('sidebarStatus', 1);
      } else {
        Cookies.set('sidebarStatus', 0);
      }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
      Cookies.set('sidebarStatus', 0);
      state.sidebar.opened = false;
      state.sidebar.withoutAnimation = withoutAnimation;
    },
    SET_SIZE: (state, size) => {
      state.size = size;
      Cookies.set('size', size);
    }
  },
  actions: {
    toggleSideBar({
      commit
    }) {
      commit('TOGGLE_SIDEBAR');
    },

    closeSideBar({
      commit
    }, {
      withoutAnimation
    }) {
      commit('CLOSE_SIDEBAR', withoutAnimation);
    },

    setSize({
      commit
    }, size) {
      commit('SET_SIZE', size);
    },

    // 中英文
    setLanguage({
      commit
    }, language) {
      commit('SET_LANGUAGE', language);
    },

    toggleDevice({
      commit
    }, device) {
      commit('TOGGLE_DEVICE', device);
    },

    bodyWidth({
      commit
    }, width) {
      commit('BODY_WIDTH', width);
    },

    login({
      commit
    }, userInfo) {
      const {
        username,
        password
      } = userInfo; // debugger

      return new Promise((resolve, reject) => {
        manageLogin({
          username: username.trim(),
          password: password
        }).then(response => {
          if (response.code == 0) {
            commit('LOGIN');
            setLogin('isLogin', true);
          }

          resolve();
        }).catch(error => {
          reject(error);
        });
      });
    },

    logout({
      commit,
      state,
      dispatch
    }) {
      // debugger
      commit('LOGOUT');
      removeLogin('isLogin');
    } // managelogin({ commit }, userInfo) {
    // 	const { username, password } = userInfo
    // 	return new Promise((resolve, reject) => {
    // 		manageLogin({ username: username.trim(), password: password }).then(response => {
    // 			commit('login')
    // 			setLogin('isLogin',true)
    // 			resolve()
    // 		}).catch(error => {
    // 			reject(error)
    // 		})
    // 	})
    // },
    // backLogout({ commit, state, dispatch }) {
    // 	// return new Promise((resolve, reject) => {
    // 	logout(state.token).then(() => {
    // 	commit('logout', [])
    // 	removeLogin('isLogin')
    // 	resetRouter()
    // 	// dispatch('tagsView/delAllViews', null, { root: true })
    // 	// resolve()
    // 	}).catch(error => {
    // 	  reject(error)
    // 	})
    // 	// })
    // },


  }
});
export default store;