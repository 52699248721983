import Cookies from 'js-cookie'; // export function getToken(key='token') {
//     return localStorage.getItem(key)
// }
// export function setToken(key='token',token) {
//     return localStorage.setItem(key, token)
// }
// export function removeToken(key='token') {
//     return localStorage.removeItem(key)
// }

export function getLogin(key = 'isLogin') {
  return Cookies.get(key);
}
export function setLogin(key = 'isLogin', loginStatus) {
  var nowTime = new Date();
  var Days = 30;
  nowTime.setTime(nowTime.setTime() + Days * 24 * 60 * 60 * 1000); // nowTime.setMinutes(nowTime.getMinutes())

  return Cookies.set(key, loginStatus);
}
export function removeLogin(key = 'isLogin') {
  return Cookies.remove(key);
}