var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dropdown",
    {
      staticClass: "international",
      attrs: { trigger: "click" },
      on: { command: _vm.handleSetLanguage }
    },
    [
      _c("div", [_vm._v(" 中英文 ")]),
      _c(
        "el-dropdown-menu",
        { attrs: { slot: "dropdown" }, slot: "dropdown" },
        [
          _c(
            "el-dropdown-item",
            { attrs: { command: "zh", disabled: _vm.language === "zh" } },
            [_vm._v("中文")]
          ),
          _c(
            "el-dropdown-item",
            { attrs: { command: "en", disabled: _vm.language === "en" } },
            [_vm._v("English")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }