import axios from 'axios';
import { Loading, MessageBox, Message } from 'element-ui';
import store from '@/store';
import { getToken } from '@/common/cache';
import route from 'vue-router';
let loadinginstace; // create an axios instance

const service = axios.create({
  baseURL: 'http://118.31.185.47/',
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 220000 // request timeout

}); // request interceptor

service.interceptors.request.use(config => {
  // do something before request is sent
  if (!config.noLoading) {
    loadinginstace = Loading.service({
      fullscreen: true
    });
  } // if (getToken('token')) {
  //   // let each request carry token
  //   // ['X-Token'] is a custom headers key
  //   // please modify it according to the actual situation
  //   config.headers['token'] = getToken('token')
  //   // if (!config.params) {
  //   //   config.params = {}
  //   // }
  //   //
  //   // config.params.token = getToken()
  // }


  return config;
}, error => {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
response => {
  const res = response.data;

  if (loadinginstace) {
    loadinginstace.close();
  } // if the custom code is not 20000, it is judged as an error.


  if (res.code !== 0) {// Message({
    //   message: res.msg || 'Error',
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // if (res.code == '100001' ) {
    //   MessageBox.confirm('登录信息失效', '重新登录', {
    //     confirmButtonText: '确定',
    //     cancelButtonText: '取消',
    //     type: 'warning'
    //   }).then(() => {
    //     store.dispatch('logout').then(() => {
    //       location.reload()
    //     })
    //   })
    // }
    // return Promise.reject(new Error(res.msg || 'Error'))
  } else {
    return res;
  }
}, error => {
  if (loadinginstace) {
    loadinginstace.close();
  } // Message({
  //   message: error.message,
  //   type: 'error',
  //   duration: 5 * 1000
  // })


  return Promise.reject(error);
});
export default service;