// const title = location.origin + '/venture/'
// const title = 'http://10.55.71.21:9095/venture/'
const title = 'https://www.nhhventures.com/venture/';
const api = {
  api_title: title,
  // 后台
  common_upload: title + 'manage/common/upload',
  // 上传
  // 视频
  getVideoList: title + 'video/pageList',
  // 视频列表
  updateVideoList: title + 'video/update',
  // 视频列表保存
  // 关于
  getAboutUsList: title + 'manage/module/getAboutUsList',
  updateAboutUS: title + 'manage/module/update',
  // 关于我们编辑保存
  // 投资者列表
  investorInfoList: title + 'investorInfo/investorInfoList',
  //查询投资者列表
  updateInvestorInfo: title + 'investorInfo/update',
  // 更新投资者列表
  insertInvestorInfo: title + 'investorInfo/insert',
  //新增投资者列表
  deleteInvestorInfo: title + 'investorInfo/delete',
  // 删除投资者列表
  // 投资组合
  listPortfolio: title + 'portfolio/pageList',
  //查询
  updatePortfolio: title + 'portfolio/update',
  // 更新
  deletePortfolio: title + 'portfolio/delete',
  //删除
  insertPortfolio: title + 'portfolio/insert',
  // 新增
  // 新闻
  listPressCenter: title + 'pressCenter/pageList',
  //查询
  updatePressCenter: title + 'pressCenter/update',
  // 更新
  deletePressCenter: title + 'pressCenter/delete',
  //删除
  insertPressCenter: title + 'pressCenter/insert',
  // 新增
  getPressCenterInfo: title + 'pressCenter/getPressCenterInfo',
  //查询新闻详情
  // 联系我们
  listContactUs: title + 'contactUs/pageList',
  //查询
  updateContactUs: title + 'contactUs/update',
  // 更新
  deleteContactUs: title + 'contactUs/delete',
  //删除
  insertContactUs: title + 'contactUs/insert',
  // 新增
  // 公共设置
  listPublicSettings: title + 'publicSettings/pageList',
  //查询
  updatePublicSettings: title + 'publicSettings/update',
  // 更新
  deletePublicSettings: title + 'publicSettings/delete',
  //删除
  insertPublicSettings: title + 'publicSettings/insert',
  // 新增
  // 大图
  listTopPicture: title + 'topPicture/pageList',
  //查询
  updateTopPicture: title + 'topPicture/update',
  // 更新
  deleteTopPicture: title + 'topPicture/delete',
  //删除
  insertTopPicture: title + 'topPicture/insert',
  // 新增
  manage_login: title + 'manage/login'
};
export default api;