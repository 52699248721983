import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
import Layout from '@/layout/index';
export const constantRoutes = [{
  path: '/',
  component: Layout,
  meta: {},
  redirect: '/about',
  children: [{
    path: '/about',
    name: 'about',
    component: () => import('@/views/about'),
    meta: {
      title: '首页'
    }
  }, {
    path: '/articleDetail',
    name: 'articleDetail',
    component: () => import('@/views/articleDetail'),
    meta: {
      title: ''
    }
  }, {
    path: '/investment',
    name: 'investment',
    component: () => import('@/views/investment'),
    meta: {
      title: '投资策略'
    }
  }, {
    path: '/team',
    name: 'team',
    component: () => import('@/views/team'),
    meta: {
      title: '团队'
    }
  }, {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import('@/views/portfolio'),
    meta: {
      title: '投资组合'
    }
  }, {
    path: '/news',
    name: 'news',
    component: () => import('@/views/news'),
    meta: {
      title: '新闻中心'
    }
  }, {
    path: '/contact',
    name: 'contact',
    component: () => import('@/views/contact'),
    meta: {
      title: '联系我们'
    }
  }]
}];

const createRouter = () => new Router({
  // mode:'history',
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});

const router = createRouter();
export default router;